/* eslint-disable no-unused-vars */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import membershipPlanService from '@/http/requests/membership/plan'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: async loadOptions => {
    let data = []
    let totalCount = 0
    const response = await membershipPlanService.getListByFilter()
    data = response.data.body
    totalCount = response.data.body.length
    return {
      data: data,
      totalCount: totalCount,
    }
  },
})

const membershipDataSource = new DataSource({
  store,
})

export {
  membershipDataSource as MembershipDataSource,
}
