<template>
  <div class="container-fluid">
    <div class="row">
      <div class="d-flex px-0 mx-0">
        <div class="p-0 flex-grow-1">
          <div class="">
            <dx-data-grid
            id="membershipPlanGrid"
            ref="membershipPlanGridRef"
            :data-source="MembershipDataSource"
            :show-borders="true"
            :height="'calc(100vh - 140px)'"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :repaint-changes-only="false"
            @editor-preparing="onEditorPreparing"
            >
            <dx-toolbar>
              <dx-item location="after" template="addButtonTemplate" />
              <dx-item name="searchPanel" css-class="pr-half" />
              <dx-item location="after" template="refreshButtonTemplate" />
            </dx-toolbar>
            <dx-remote-operations
              :paging="false"
              :sorting="false"
              :filtering="false"
            />
            <dx-search-panel :visible="true" :width="300" placeholder="Search..." />
            <dx-paging :page-size="pagerOptions.pageSize" />
            <dx-pager
              :allowed-page-sizes="pagerOptions.allowedPageSizes"
              :show-info="pagerOptions.showInfo"
              :show-navigation-buttons="pagerOptions.showNavigationButtons"
              :show-page-size-selector="pagerOptions.showPageSizeSelector"
              :visible="pagerOptions.visible"
            />
            <template #addButtonTemplate>
              <div>
                <dx-util-button icon="add" text="New Plan" type="success" hint="Create a new plan" @click="addNewPlan" />
              </div>
            </template>
            <template #refreshButtonTemplate>
              <div>
                <dx-util-button icon="refresh" hint="Refresh plans" @click="refreshPlans" />
              </div>
            </template>
            <dx-column data-field="name" />
            <dx-column data-field="code" />
            <dx-column data-field="description" :width="300" />
            <dx-column data-field="memberAudienceType" :width="120" caption="Audience Type" />
            <dx-column data-field="monthlyFee" :width="120" :format="'$ #,##0.##'" />
            <dx-column data-field="isActive" :width="100" />
            <dx-column data-field="isThereFreeTrial" caption="Free Trial" :width="100" />
            <dx-column data-field="freeTrialDay" :width="150" />
            <dx-column data-field="yearlyDiscount" :width="150" />
            <dx-column data-field="itemLimit" caption="Item Limit" :width="120" format="#,##0" />
            <dx-column data-field="extraItemMonthlyFee" caption="Extra Item Fee" :width="120" />
            <dx-column data-field="storeLimit" caption="Store Limit" :width="120" />
            <dx-column data-field="extraStoreMonthlyFee" caption="Extra Store Fee" :width="120" />
            <dx-column data-field="userLimit" caption="User Limit" :width="120" />
            <dx-column data-field="extraUserMonthlyFee" caption="Extra User Fee" :width="120" />
            <dx-column data-field="iconUrl" :width="60" cell-template="iconTemplate" caption="Icon" alignment="center" :allow-sorting="false" />
            <template #iconTemplate="{data}">
              <div>
                <p-icon :name="data.value" />
               </div>
            </template>

            <dx-column data-field="createdBy" :width="110" />
            <dx-column data-field="creationTime" :width="110" data-type="date" format="yyyy/MM/dd" caption="Created At" />
            <dx-column cell-template="rowActions" :width="160" alignment="center" caption="Actions" />
            <template #rowActions="{data}">
              <div id="membership">
                <dx-util-button
                  type="warning" icon="bi bi-pencil-square"
                  class="mr-half" hint="Edit plan"
                  @click="editMembershipPlan(data)"
                />
                <dx-util-button
                  type="danger" icon="bi bi-trash"
                  class="mr-half" hint="Delete plan"
                  @click="deleteMembershipPlan(data)"
                />
                <dx-util-drop-down-button
                  :split-button="false"
                  :use-select-mode="true"
                  :drop-down-options="{ width: 210 }"
                  :show-arrow-icon="true"
                  icon="menu"
                  display-expr="name"
                  drop-down-content-template="dropDownTemplate"
                  class="px-0"
                >
                <template #dropDownTemplate>
                  <div class="d-flex flex-column">
                    <dx-util-button text="View Plan Details" icon="icon bi-grip-horizontal" class="mx-0 w-100 text-left" @click="viewPlanDetails(data)" />
                    <dx-util-button text="View Plan Subscriptions" icon="icon bi-list-task" class="mx-0 w-100 text-left" @click="viewPlanSubscriptions(data)" />
                  </div>
                </template>
                </dx-util-drop-down-button>
              </div>
            </template>
            </dx-data-grid>

          </div>
        </div>
      </div>
      <div>
        <membership-plan-form :component-id="planFormComponentId"
          :membership-plan-data="selectedMembershipPlan"
          @emit-form-updated="reloadGrid"
          @emit-form-created="reloadGrid"
          @emit-form-closed="resetMembershipPlanSelection"
        />
        <membership-plan-detail-form :component-id="planDetailFormComponentId" :plan-data="selectedMembershipPlan" />
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { confirm } from 'devextreme/ui/dialog'
import membershipPlanService from '@/http/requests/membership/plan'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { MembershipDataSource } from './membershipPlanStore'

// import { Notify } from '@robustshell/utils/index'
export default {
  components: {
    'membership-plan-form': () => import('./components/MembershipPlanForm'),
    'membership-plan-detail-form': () => import('./components/MembershipPlanDetailForm.vue'),
  },
  mixins: [GridBase],
  data() {
    return {
      membershipPlans: [],
      MembershipDataSource,
      planDetailFormComponentId: '',
      planFormComponentId: '',
      packageComponentId: '',
      selectedMembershipPlan: {},
      searchQuery: '',
    }
  },
  computed: {
    selectedTypeId() {
      return this.selectedMembershipType?.id
    },
  },
  methods: {
    async reloadGrid() {
      setTimeout(() => {
        MembershipDataSource.reload()
      }, 1000)
    },
    resetMembershipPlanSelection() {
      this.selectedMembershipPlan = {}
    },
    editMembershipPlan(e) {
      const plan = e.data
      this.selectedMembershipPlan = plan
      this.planFormComponentId = uuidv4()
    },
    deleteMembershipPlan(e) {
      const { id, name } = e.data
      const confirmResult = confirm(
        `Are you sure you want to delete this membership plan: ${name}?`,
        'Confirmation',
      )
      confirmResult.then(async dialogResult => {
        if (dialogResult) {
          await membershipPlanService.delete(id)
          MembershipDataSource.reload()
        }
      })
    },
    onEditorPreparing(e) {
      if (e.parentType !== 'searchPanel') return
      e.editorOptions.onValueChanged = arg => {
        const query = arg.component.option('value')
        if (!query) {
          this.searchQuery = query.trim()
          e.component.searchByText('')
        }
      }
      e.editorOptions.onKeyDown = arg => {
        if (arg.event.keyCode === 13) {
          let query = arg.component.option('value')
          query = query.trim()
          arg.component.option('value', query)
          this.searchQuery = query
          e.component.searchByText(query)
        }
        if (arg.event.keyCode === 27) {
          e.component.searchByText('')
          arg.component.option('value', '')
          this.searchQuery = ''
        }
      }
    },
    addNewPlan() {
      this.planFormComponentId = uuidv4()
    },
    viewPlanDetails(e) {
      const plan = e.data
      this.selectedMembershipPlan = plan
      this.planDetailFormComponentId = uuidv4()
    },
    viewPlanSubscriptions(e) {
      const plan = e.data
      this.$router.push({ name: 'membershipsubscribers', query: { qPlanId: plan.id } })
    },
    refreshPlans() {
      MembershipDataSource.reload()
    },
  },
}
</script>

<style lang="scss">
.list-group-item {
  transition: background-color 0.3s ease;
}
.list-group-item:active {
  background-color: rgba(0, 123, 255, 0) !important;
}
.selected-card {
  background-color: rgba(36, 122, 148, 0.3) !important;
}
#membership .dx-dropdownbutton {
  background-color: #5BC2D5;
  border-radius: 4px;
}
</style>
